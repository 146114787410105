import { useEffect } from "react";
import "../../css/Payment.css";
import { useTranslation } from "react-i18next";
import { SUCC_COLOR } from "../../config/colors";
import Image from "../../config/Images";
import { getFromAsync } from "../../services/utils";
import { SESSION_KEY } from "../../constant";

const SuccessScreen = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    commonFunc();
  }, []);

  const commonFunc = async () => {
    let lang = await getFromAsync(SESSION_KEY?.LANGUAGE);
    i18n?.changeLanguage(lang?.langCode);
  };

  return (
    <div
      className=""
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <div>
        <div
          className="df"
          style={{ justifyContent: "center", padding: "20px 0px" }}
        >
          <img
            src={Image?.Success}
            style={{ width: "30%", height: "30%" }}
            alt=""
          />
        </div>
        <div
          className=""
          style={{ justifyContent: "center", padding: "20px 0px" }}
        >
          <p style={{ fontSize: 26, color: SUCC_COLOR, textAlign: "center" }}>
            {t("Your Appointment")}
          </p>
          <p style={{ fontSize: 26, color: SUCC_COLOR, textAlign: "center" }}>
            {t("is successfully confirmed")}
          </p>
        </div>
        <div
          className="df"
          style={{ justifyContent: "center", padding: "20px 0px" }}
        >
          <a href={"/"} rel="noreferrer">
            {t(`${"Go to Home"}`)}
          </a>
        </div>
      </div>
    </div>
  );
};

export default SuccessScreen;
