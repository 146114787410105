import React, { createContext, useContext, useState } from "react";

export const AppContext = createContext({});
const AppProvider = ({ children }) => {
  const [appData, setAppData] = useState({});
  return (
    <AppContext.Provider value={{ appData, setAppData }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;

const UseAppData = () => {
  return useContext(AppContext);
};

const SetDataContext = (val) => {
  const context = useContext(AppContext);
  context.setAppData({
    ...context,
    appData: {
      ...context?.appData,
      data: { ...context?.appData?.data, ...val },
    },
  });
  return null;
};

const SetExtraDataContext = (val) => {
  const context = useContext(AppContext);
  context.setAppData({
    ...context,
    appData: {
      ...context?.appData,
      extraData: { ...context?.appData?.extraData, ...val },
    },
  });
  return null;
};

export { UseAppData, SetDataContext, SetExtraDataContext };
