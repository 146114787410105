const BASE_URL = "https://api.book-dentonio.com";

const API_CONSTANT = {
  BASE_URL: BASE_URL,
  LANGUAGE: "language",
  SERVICE_TYPE: "service-type",
  SERVICE: "service",
  PAYMENT_TYPE: "payment-type",
  ACCOMMODATION: "accommodation",
  APPOINTMENT: "appointment",
  COUNTRY: "country",
  CUSTOMER_APPOINTMENT: "customer-appointment",
};

export default API_CONSTANT;
