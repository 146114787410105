import Accordion from "./../screens/accordion";
import Success from "./../screens/successScreen";

import { Routes, Route, BrowserRouter } from "react-router-dom";

function Navigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Accordion />} />
        <Route path="/success" element={<Success />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Navigation;
