import API_CONSTANT from "../../services/ApiConstant";
import { get, post } from "../../services/ApiServices";

const languageApi = (data) => {
  return get(`${API_CONSTANT.LANGUAGE}`, null, false);
  // return new Promise((resolve, reject) => {
  //     .then((res) => {
  //       resolve(res);
  //     })
  //     .catch((err) => {
  //       reject(err);
  //     });
  // });
};

const serviceTypeApi = (data, header) => {
  return get(`${API_CONSTANT.SERVICE_TYPE}`, data, header);
};

const serviceApi = (data, header) => {
  return get(`${API_CONSTANT.SERVICE}`, data, header);
};

const paymentTypeApi = (data, header) => {
  return get(`${API_CONSTANT.PAYMENT_TYPE}`, data, header);
};

const accommodationApi = (data, header) => {
  return get(`${API_CONSTANT.ACCOMMODATION}`, data, header);
};

const appointmentApi = (data, header) => {
  return get(`${API_CONSTANT.APPOINTMENT}`, data, header);
};

const countryApi = (data, header) => {
  return get(`${API_CONSTANT.COUNTRY}`, data, header);
};

const custAppointmentApi = (data, header) => {
  return post(`${API_CONSTANT.CUSTOMER_APPOINTMENT}`, data, header);
};

export {
  languageApi,
  serviceTypeApi,
  serviceApi,
  paymentTypeApi,
  accommodationApi,
  appointmentApi,
  countryApi,
  custAppointmentApi,
};
