import { useCallback, useContext, useEffect, useState } from "react";
import "../../css/FirstVisit.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DATE,
  getFromAsync,
  NO,
  setInAsyncStorage,
  TEXT,
  TIME,
  YES,
} from "../../services/utils";
import { accommodationApi, countryApi } from "../../store/ApiMethod/action";
import { BLACK, THEME_COLOR, WHITE } from "../../config/colors";
import { SESSION_KEY } from "../../constant/AppConstant";
import { AppContext } from "../../context/AppProvider";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Calendar } from "@natscale/react-calendar";
import "@natscale/react-calendar/dist/main.css";
import moment from "moment";
import Modal from "react-modal";
import Image from "../../config/Images";
import * as yup from "yup";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-10%",
    transform: "translate(-50%, -50%)",
  },
};
const customStylesMobile = {
  content: {
    top: "0%",
    left: "0%",
    marginRight: "-10%",

    transform: "translate(0%, 0%)",
  },
};

function FirstVisit() {
  const { t } = useTranslation();

  const [escortValue, setEscortValue] = useState("");
  const [currentRadioValue, setCurrentValue] = useState("");
  const [currentAirportValue, setAirportValue] = useState("");
  const [accomodationList, setAccomodationList] = useState([]);
  const [selectHotel, setSelectHotel] = useState("");
  const [servDuration, setServDuration] = useState("");
  const [apmDates, setApmDates] = useState([]);
  const [dateTime, setDateTime] = useState({});
  const context = useContext(AppContext);

  const [countryList, setCountryList] = useState([]);
  const [value, setValue] = useState();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});
  const [escDataArr, setEscDataArr] = useState([]);

  const [ESCORT_ARR, setEscort] = useState([]);

  const _onDatePress = useCallback(
    async (value, i) => {
      setValue();
      let service = await getFromAsync(SESSION_KEY?.SERV);
      let dates = await getFromAsync(SESSION_KEY?.APM_DATES);

      let index = dates?.findIndex(
        (x) =>
          moment(x).format("MM-DD-YYYY") ===
          moment(value[0]).format("MM-DD-YYYY")
      );

      setServDuration(service?.duration);

      let check = dates?.length - index;
      if (service?.duration <= check) {
        let endDate = dates?.filter((d, i) => {
          if (i == service?.duration + (index - 1)) {
            return d;
          }
        });
        setInAsyncStorage(SESSION_KEY?.START_DATE, moment(value[0]).format());
        setInAsyncStorage(SESSION_KEY?.END_DATE, endDate[0]);
        context.setAppData({
          ...context,
          appData: {
            sd: moment(value[0]).format("MM-DD-YYYY"),
            ed: endDate[0],
          },
        });

        setValue(value);
      } else {
        setValue();
        setInAsyncStorage(SESSION_KEY?.START_DATE, null);
        setInAsyncStorage(SESSION_KEY?.END_DATE, null);
        context.setAppData({
          ...context,
          appData: { sd: null, ed: null },
        });
        toast.error(t(`${"Please select different date for appointment"}`));
      }
    },
    [setValue]
  );

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
    });
  };

  useEffect(() => {
    commonFunc();
    _onDatePress();
  }, [context?.appData?.serviceUid]);
  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    setInAsyncStorage(SESSION_KEY?.EST_DATE_TIME, dateTime);
  }, [dateTime]);

  const commonFunc = async () => {
    let lang = await getFromAsync(SESSION_KEY?.LANGUAGE);
    let esc = await getFromAsync(SESSION_KEY?.ESCORT_LIST);
    setEscort(esc);
    let dates = await getFromAsync(SESSION_KEY?.APM_DATES);
    setApmDates(dates);
    AccommodationListApi(lang?.langCode);
    CountryListApi(lang?.langCode);
    setInAsyncStorage(SESSION_KEY?.START_DATE, null);
    setInAsyncStorage(SESSION_KEY?.END_DATE, null);
    context.setAppData({
      ...context,
      appData: { sd: null, ed: null },
    });
  };

  const CountryListApi = (lang) => {
    countryApi(null, lang)
      .then((res) => {
        setCountryList(res?.data);
      })
      .catch((err) => {});
  };

  const AccommodationListApi = (lang) => {
    accommodationApi(null, lang)
      .then((res) => {
        setAccomodationList(res?.data);
      })
      .catch((err) => {});
  };

  const _onHotelSelect = (val) => {
    setSelectHotel(val);
    setInAsyncStorage(SESSION_KEY?.ACCOMODATION, val);
    context.setAppData({
      ...context,
      appData: { accomData: val?.uid },
    });
  };

  const handleEscort = (val) => {
    setInAsyncStorage(SESSION_KEY?.ESCORT, { escortValue: val });
    setEscortValue(val);
    context.setAppData({
      ...context,
      appData: { escortValue: val },
    });
  };

  const handleChange = async (val, field) => {
    setData({ ...data, [field]: val });
    context.setAppData({
      ...context,
      appData: { escortDetails: { ...data, [field]: val } },
    });
  };

  const _onAddEscort = () => {
    let arr = [...escDataArr];
    if (!data?.name == "") {
      yup
        .object()
        .shape({
          phone: yup.number().typeError("phone number is must be a number"),
          email: yup
            .string("email is required")
            .email("please enter valid email"),
          type: yup
            .string("please select escort")
            .required("escort type is required"),
          country: yup
            .string("please select country")
            .required("country is required"),
          city: yup
            .string("city is must be string")
            .required("city is required"),
          surname: yup
            .string("surname is must be string")
            .required("surname is required"),
          name: yup
            .string("name is must be string")
            .required("name is required"),
        })
        .validate(data)
        .then((valid) => {
          if (escDataArr?.length == 0) {
            arr.push(data);
            setEscDataArr(arr);
            setInAsyncStorage(SESSION_KEY?.ESCORT_DATA, arr);
            context.setAppData({
              ...context,
              appData: { escList: arr },
            });
            closeModal();
            setData({});
          } else {
            var index = arr.findIndex((x) => x.name == data?.name);
            if (index === -1) {
              setEscDataArr([...arr, data]);
              setInAsyncStorage(SESSION_KEY?.ESCORT_DATA, [...arr, data]);
              context.setAppData({
                ...context,
                appData: { escList: [...arr, data] },
              });
              closeModal();
              setData({});
            } else {
              toast.error(t(`${"Data already exist"}`));
            }
          }
        })
        .catch((e) => {
          toast.error(t(`${e?.message}`));
        });
    } else {
      toast.error(t(`${"Your data is empty, please fill the all fields"}`));
    }
  };

  const _onEscortRemove = (ind) => {
    let escData = escDataArr?.filter((d, i) => {
      if (i !== ind) {
        return d;
      }
    });
    setEscDataArr(escData);
    setInAsyncStorage(SESSION_KEY?.ESCORT_DATA, escData);
    context.setAppData({
      ...context,
      appData: { escList: escData },
    });
  };

  const handleDateTime = (val, field) => {
    setDateTime({ ...dateTime, [field]: val });
    context.setAppData({
      ...context,
      datetime: { ...dateTime, [field]: val },
    });
  };

  const handleAccom = (val) => {
    setCurrentValue(val);
    setInAsyncStorage(SESSION_KEY?.ACCOM_CHECK, { accomCheck: val });
    context.setAppData({
      ...context,
      appData: { accomCheck: val },
    });
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  let modalView =
    screenSize?.dynamicWidth > 500 ? customStyles : customStylesMobile;

  return (
    <div className="main-container">
      <div className="heading">
        <div style={{ marginBottom: 20 }}>
          <h4>{t(`${TEXT?.CHOOSE_DATE_TIME}`)}</h4>
        </div>
        <Calendar
          value={value}
          onChange={_onDatePress}
          fixedRange={servDuration - 1}
          isDisabled={(val) =>
            !apmDates.filter(
              (e) =>
                moment(val).format("MM-DD-YYYY") ===
                moment(e).format("MM-DD-YYYY")
            ).length
          }
          isHighlight={(val) =>
            apmDates.filter(
              (e) =>
                moment(val).format("MM-DD-YYYY") ===
                moment(e).format("MM-DD-YYYY")
            ).length
          }
          isRangeSelector
        />
      </div>
      <div className="heading">
        <h4>{t(`${TEXT?.ARE_YOU_COMING}`)}</h4>
        <div className="info" style={{ marginLeft: 0 }}>
          <input
            id={1}
            type="radio"
            name="escort"
            value="true"
            onChange={(e) => handleEscort(e.target.value)}
            defaultChecked={escortValue === "true"}
          />
          <label htmlFor={1}>{t(YES)}</label>
          <input
            id={2}
            type="radio"
            name="escort"
            value="false"
            onChange={(e) => handleEscort(e.target.value)}
            defaultChecked={escortValue === "false"}
          />
          <label htmlFor={2}>{t(NO)}</label>

          {escortValue === "true" && (
            <div className="container" style={{ marginTop: 20 }}>
              <div
                onClick={openModal}
                className="fnwidth40 button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #000",
                  height: 50,
                  cursor: "pointer",
                  borderRadius: 5,
                }}
              >
                <p
                  style={{
                    fontSize: 18,
                    marginLeft: 5,
                    textAlign: 'center',
                  }}
                >
                  <strong>{t(`${TEXT?.ADD_ESC_DET}`)}</strong>
                </p>
              </div>

              {escDataArr?.map((d, i) => {
                return (
                  <div
                    key={i}
                    className="fnwidth40"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid",
                      height: 40,
                      borderRadius: 5,
                      padding: "0px 10px",
                      marginTop: 10,
                    }}
                  >
                    <p
                      style={{
                        color: "#002944",
                        fontSize: 18,
                        marginLeft: 5,
                        width: "100%",
                      }}
                    >
                      <strong>
                        {i + 1} - {d?.name} {d?.surname}
                      </strong>
                    </p>
                    <div onClick={() => _onEscortRemove(i)}>
                      <img
                        className="card"
                        src={Image?.Remove}
                        alt="visitCard"
                        style={{ top: 2, cursor: "pointer" }}
                      />
                    </div>
                  </div>
                );
              })}

              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={modalView}
                contentLabel="Example Modal"
                ariaHideApp={false}
              >
                <form>
                  <div className="information-form">
                    <div className="width100 df">
                      <div className="info fnwidth40">
                        <p>
                          <span style={{ color: "red" }}>*</span>{" "}
                          {t(`${TEXT?.FIRST_NAME}`)}
                        </p>
                        <input
                          type="text"
                          className=""
                          onChange={(e) =>
                            handleChange(e?.target?.value, "name")
                          }
                          required
                        />
                      </div>
                      <div className="info lnwidth35">
                        <p>
                          <span style={{ color: "red" }}>*</span>{" "}
                          {t(`${TEXT?.LAST_NAME}`)}
                        </p>
                        <input
                          type="text"
                          className=""
                          onChange={(e) =>
                            handleChange(e?.target?.value, "surname")
                          }
                          required
                        />
                      </div>
                      <div className="info gnwidth25">
                        <p>
                          <span style={{ color: "red" }}>*</span>{" "}
                          {t(`${TEXT?.CITY}`)}
                        </p>
                        <input
                          type="text"
                          className=""
                          onChange={(e) =>
                            handleChange(e?.target?.value, "city")
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="width100 df">
                      <div className="info fnwidth40">
                        <p>
                          <span style={{ color: "red" }}>*</span>{" "}
                          {t(`${TEXT?.COUNTRY}`)}
                        </p>
                        <select
                          className="fnwidth40"
                          style={{ fontSize: 20, height: 34, width: "100%" }}
                          onChange={(e) =>
                            handleChange(e?.target?.value, "country")
                          }
                        >
                          <option selected={true} disabled={true} hidden={true}>
                            - - -
                          </option>
                          {countryList.map((item, i) => (
                            <option key={i} value={item?.uid}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="info lnwidth35">
                        <p>
                          <span style={{ color: "red" }}>*</span>{" "}
                          {t(`${TEXT?.ESCORT}`)}
                        </p>
                        <select
                          className="fnwidth40"
                          style={{ fontSize: 20, height: 34, width: "100%" }}
                          onChange={(e) =>
                            handleChange(e?.target?.value, "type")
                          }
                        >
                          <option selected={true} disabled={true} hidden={true}>
                            - - -
                          </option>
                          {ESCORT_ARR.map((item, i) => (
                            <option key={i} value={item?.value}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="info gnwidth25"></div>
                    </div>
                    <div className="width100 df">
                      <div className="info fnwidth40">
                        <p>{t(`${TEXT?.EMAIL_ID}`)}</p>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className=""
                          onChange={(e) =>
                            handleChange(e?.target?.value, "email")
                          }
                        />
                      </div>
                      <div className="info lnwidth35">
                        <p>{t(`${TEXT?.PH_NO}`)}</p>
                        <input
                          type="text"
                          className=""
                          onChange={(e) =>
                            handleChange(e?.target?.value, "phone")
                          }
                        />
                      </div>
                      <div className="info gnwidth25"></div>
                    </div>
                  </div>
                </form>
                <div
                  className="df"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 20,
                    paddingBottom: 50,
                    display: screenSize?.dynamicWidth > 500 ? "flex" : "grid",
                  }}
                >
                  <button
                    className="button"
                    style={{
                      width: 200,
                      height: 50,
                      margin:
                        screenSize?.dynamicWidth > 500 ? "0px 10px" : "0px 0px",
                      marginBottom: screenSize?.dynamicWidth > 500 ? 0 : 10,
                    }}
                    onClick={() => _onAddEscort()}
                  >
                    {t(`${"Add"}`)}
                  </button>
                  <button
                    type="submit"
                    className="button"
                    style={{
                      width: 200,
                      height: 50,
                      margin:
                        screenSize?.dynamicWidth > 500 ? "0px 10px" : "0px 0px",
                    }}
                    onClick={() => closeModal()}
                  >
                    {t(`${"Cancel"}`)}
                  </button>
                </div>
              </Modal>
            </div>
          )}
        </div>
      </div>
      {/* ----------------------------------------------------------- */}
      <div className="heading">
        <h4>{t(`${TEXT?.DO_YOU_WANT_TO_BOOK}`)}</h4>
        <div className="info" style={{ marginLeft: 0 }}>
          <input
            name="accommodation"
            id={3}
            value="yes"
            type="radio"
            onChange={(e) => handleAccom(e.target.value)}
            defaultChecked={currentRadioValue === "yes"}
          />
          <label htmlFor={3}>{t(YES)}</label>
          <input
            name="accommodation"
            id={4}
            value="no"
            type="radio"
            onChange={(e) => handleAccom(e.target.value)}
            defaultChecked={currentRadioValue === "no"}
          />
          <label htmlFor={4}>{t(NO)}</label>
          {currentRadioValue === "yes" && (
            <div className="hotel">
              <h5>{t(`${TEXT?.CHOOSE_HOTEL}`)}:</h5>

              <div className="address">
                {accomodationList?.map((d, i) => {
                  let select = selectHotel?.uid == d?.uid;
                  return (
                    <div
                      key={i}
                      className="place-info"
                      onClick={() => {
                        _onHotelSelect(d);
                      }}
                      style={{
                        backgroundColor: select ? THEME_COLOR : WHITE,
                        color: select ? "white" : "black",
                      }}
                    >
                      <div className="name df, accomBox">
                        <h4 style={{ color: select ? WHITE : BLACK }}>
                          {d?.name}
                        </h4>
                      </div>
                      <p>{d?.address}</p>
                      <a href={d?.website} target="_blank" rel="noreferrer">
                        {t(`${TEXT?.VISIT_WEBSITES}`)}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {currentRadioValue === "no" && <div></div>}
        </div>
      </div>

      <div className="heading">
        <h4> {t("4. Do you need a transfer from the airport?")} </h4>
        <div className="info" style={{ marginLeft: 0 }}>
          <input
            name="airport"
            id={5}
            value="true"
            type="radio"
            onChange={(e) => setAirportValue(e.target.value)}
            defaultChecked={currentAirportValue === "true"}
          />
          <label htmlFor={5}>{t(YES)}</label>
          <input
            name="airport"
            id={6}
            value="false"
            type="radio"
            onChange={(e) => setAirportValue(e.target.value)}
            defaultChecked={currentAirportValue === "false"}
          />
          <label htmlFor={6}>{t(NO)}</label>
          {currentAirportValue === "true" && (
            <div className="transfer_time">
              <h5> {t(`${TEXT?.FLIGHT_ARRIVAL_DATE_TIME}`)}: </h5>
              <div className="date_time">
                <div>
                  <p className="date">{t(DATE)}</p>
                  <DatePicker
                    className="date"
                    selected={dateTime?.date}
                    placeholderText="--/--/--"
                    onChange={(date) => handleDateTime(date, "date")}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                  />
                </div>
                <div>
                  <p className="time">{t(TIME)}</p>
                  <DatePicker
                    placeholderText="-- : --"
                    selected={dateTime?.time}
                    onChange={(date) => handleDateTime(date, "time")}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                  />
                </div>
              </div>
            </div>
          )}
          {currentAirportValue === "false" && <div></div>}
        </div>
      </div>
    </div>
  );
}

export default FirstVisit;
