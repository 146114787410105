import React, { useEffect } from "react";
import AppProvider from "./context/AppProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./services/i18n";
import Navigation from "./route/navigation";

const App = () => {
  useEffect(() => {
    sessionStorage.clear();
  }, []);
  return (
    <>
      <AppProvider>
        <ToastContainer />
        <Navigation />
      </AppProvider>
    </>
  );
};

export default App;
