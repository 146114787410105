import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SESSION_KEY } from "../../constant/AppConstant";
import { AppContext } from "../../context/AppProvider";
import "../../css/PatientInfo.css";
import { getFromAsync, setInAsyncStorage, TEXT } from "../../services/utils";
import { countryApi } from "../../store/ApiMethod/action";

const PatientInfo = ({ setComplete }) => {
  const { t } = useTranslation();

  const [countryList, setCountryList] = useState([]);
  const [data, setData] = useState({});

  const appData = useContext(AppContext);

  useEffect(() => {
    setComplete(2);
    if (appData?.appData?.langCode != undefined) {
      commonFunc();
    }
  }, [appData]);

  useEffect(() => {
    setComplete(2);
  }, [appData?.appData?.patientInfo]);

  useEffect(() => {
    commonFunc();
  }, []);

  useEffect(() => {
    setInAsyncStorage(SESSION_KEY?.PATIENT_INFO, data);
  }, [data]);

  const commonFunc = async () => {
    let lang = await getFromAsync(SESSION_KEY?.LANGUAGE);
    CountryListApi(lang?.langCode);
  };

  const CountryListApi = (lang) => {
    countryApi(null, lang)
      .then((res) => {
        setCountryList(res?.data);
      })
      .catch((err) => {});
  };

  const handleChange = async (val, field) => {
    setData({ ...data, [field]: val });
    appData.setAppData({
      ...appData,
      patientInfo: { ...data, [field]: val },
    });
  };

  return (
    <div className="container">
      <form>
        <div className="information-form">
          <div className="width100 df">
            <div className="info fnwidth40">
              <p>
                <span style={{ color: "red" }}>*</span>{" "}
                {t(`${TEXT?.FIRST_NAME}`)}
              </p>
              <input
                type="text"
                className=""
                onChange={(e) => handleChange(e?.target?.value, "name")}
                required
              />
            </div>
            <div className="info lnwidth35">
              <p>
                <span style={{ color: "red" }}>*</span>{" "}
                {t(`${TEXT?.LAST_NAME}`)}
              </p>
              <input
                type="text"
                className=""
                onChange={(e) => handleChange(e?.target?.value, "surname")}
              />
            </div>
            <div className="info gnwidth25">
              <p>
                <span style={{ color: "red" }}>*</span> {t(`${TEXT?.CITY}`)}
              </p>
              <input
                type="text"
                className=""
                onChange={(e) => handleChange(e?.target?.value, "city")}
              />
            </div>
          </div>

          <div className="width100 df" style={{ marginBottom: "40px" }}>
            <div className="info fnwidth40">
              <p>
                <span style={{ color: "red" }}>*</span> {t(`${TEXT?.COUNTRY}`)}
              </p>
              <select
                defaultValue={""}
                className="fnwidth40"
                style={{ fontSize: 20, height: 34, width: "100%" }}
                onChange={(e) => handleChange(e?.target?.value, "country")}
              >
                <option selected={true} disabled={true} hidden={true}>
                  {t(`${TEXT?.SELECT_COUNTRY}`)}
                </option>
                {countryList.map((item, i) => (
                  <option key={i} value={item?.uid}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="info lnwidth35">
              <p>
                <span style={{ color: "red" }}>*</span> {t(`${TEXT?.EMAIL_ID}`)}
              </p>
              <input
                type="email"
                className=""
                onChange={(e) => handleChange(e?.target?.value, "email")}
              />
            </div>
            <div className="info gnwidth25">
              <p>
                <span style={{ color: "red" }}>*</span> {t(`${TEXT?.PH_NO}`)}
              </p>
              <input
                type="text"
                className=""
                onChange={(e) => handleChange(e?.target?.value, "phone")}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PatientInfo;
