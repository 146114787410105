import PatientInfo from "../screens/patientInfo/PatientInfo";
import Payment from "../screens/payment";
import Services from "../screens/services";
import * as yup from "yup";

const setInAsyncStorage = async (key, value) => {
  await sessionStorage.setItem(key, JSON.stringify(value));
};

const getFromAsync = async (key) => {
  return JSON.parse(sessionStorage.getItem(key));
};

const removeFromAsync = async (key) => {
  return sessionStorage.removeItem(key);
};

export const validationSchema = yup.object().shape({
  phone: yup
    .number()
    .typeError("phone number is must be a number")
    .required("phone number is required"),
  email: yup
    .string("email is required")
    .email("please enter valid email")
    .required("email is required"),
  country: yup.string("please select country").required("country is required"),
  city: yup.string("city is must be string").required("city is required"),
  surname: yup
    .string("surname is must be string")
    .required("surname is required"),
  name: yup.string("name is must be string").required("name is required"),
});

const Data = [
  {
    id: 1,
    title: "Make an Appointment",
    content: Services,
  },
  {
    id: 2,
    title: "Patient/Student Information",
    content: PatientInfo,
  },
  {
    id: 3,
    title: "Payment",
    content: Payment,
  },
];

export const YES = "Yes";
export const NO = "No";
export const DATE = "Date";
export const TIME = "Time";
export const MALE = "Male";
export const FEMALE = "Female";

const TEXT = {
  LANGUAGE: "Language",
  ARE_YOU: "Are You",
  TYPE_OF_SER: "Type of Service",
  FIRST_VISIT: "First Visit",
  SECOND_VISIT: "Second Visit",
  ORTHO_THER: "Orthodontic Therapy",
  EMER_INTER: "Emergency Intervention",
  CHOOSE_DATE_TIME: "Choose Date and Time*",
  FLIGHT_ARRIVAL_DATE_TIME: "Flight arrival date time",
  ADV_PAY: "Advance payment",
  PAY_BANK: "Payment in the Bank",
  CHOOSE_PAYMENT: "Choose Payment",
  ARE_YOU_COMING: "2. Are you coming with an escort?",
  DO_YOU_WANT_TO_BOOK: "3. Do you want to book accommodation?",
  CHOOSE_HOTEL: "Choose Hotel*",
  VISIT_WEBSITES: "Visit website",

  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  GENDER: "Gender",
  ADDRESS: "Address",
  COUNTRY: "Country",
  ESCORT: "Escort",
  CITY: "City",
  STATE: "State",
  ZIP_CODE: "Zip Code",
  EMAIL_ID: "Email",
  PH_NO: "Phone",
  SELECT_GENDER: "Select Gender",
  SELECT_COUNTRY: "- - -",
  SELECT_ESCORT: "Select Escort",
  ADD_ESC_DET: "Add escort details",

  START_DATE: "Start Date",
  END_DATE: "End Date",
  NO_OF_PLACES: "No of Places",

  SUBMIT: "Submit",
  ADD_NEW: "Add New",
};

export { setInAsyncStorage, getFromAsync, removeFromAsync, Data, TEXT };
