import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccordionTab } from "../../components/accordion";
import { Data } from "../../services/utils";

const Accordion = () => {
  const { t } = useTranslation();

  const [complete, setComplete] = useState(0);
  useEffect(() => {}, [complete]);

  return (
    <div>
      <div className="accordion">
        {Data.map(({ id, title, content }, i) => (
          <AccordionTab
            key={id}
            id={id}
            title={t(title)}
            Content={content}
            current={i}
            ind={i}
            complete={complete}
            setComplete={setComplete}
          />
        ))}
      </div>
    </div>
  );
};

export default Accordion;
