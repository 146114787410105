const SESSION_KEY = {
  LANGUAGE: "LANGUAGE",
  APM_LIST: "AMP_LIST",
  SERV: "SERV",
  START_DATE: "START_DATE",
  END_DATE: "END_DATE",
  APM_DATES: "AMP_DATES",
  TYPE: "TYPE",
  ESCORT_LIST: "ESCORT_LIST",
  ESCORT_DATA: "ESCORT_DATA",
  SERV_UID: "SERV_UID",
  PATIENT_INFO: "PATIENT_INFO",
  ACCOMODATION: "ACCOMODATION",
  PAY_TYPE: "PAY_TYPE",
  EST_DATE_TIME: "EST_DATE_TIME",
  ESCORT: "ESCORT",
  ACCOM_CHECK: "ACCOM_CHECK",
};

const API_KEY = {
  customer: "customer",
  appointment: "appointment",
  accommodation: "accommodation",
  paymentType: "paymentType",
  estimatedArrivalTime: "estimatedArrivalTime",
  transferRequired: "transferRequired",
};

export { SESSION_KEY, API_KEY };
