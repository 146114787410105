import "../../css/Services.css";
import "../../css/Calendar.css";
import {useContext, useEffect, useState} from "react";
import FirstVisit from "./../firstVisit";
import {languageApi, serviceTypeApi} from "../../store/ApiMethod/action";
import {THEME_COLOR, WHITE, YELLOW_LIGHTER} from "../../config/colors";
import {setInAsyncStorage, TEXT} from "../../services/utils";
import {AppContext} from "../../context/AppProvider";
import {API_KEY, SESSION_KEY} from "../../constant/AppConstant";
import {useTranslation} from "react-i18next";
import moment from "moment";

function Services({setComplete}) {
  const {t, i18n} = useTranslation();

  const [language, setLanguage] = useState([]);
  const [selectedLang, setSelectedLang] = useState("");
  const [serTypeList, setSerTypeList] = useState([]);
  const [selectAY, setSelectAY] = useState("");
  const [serviceList, setServiceList] = useState([]);
  const [selService, setSelService] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const context = useContext(AppContext);

  useEffect(() => {
    LangListApi();
  }, []);

  // -------------------------------------
  const LangListApi = () => {
    languageApi()
      .then((res) => {
        setLanguage(res?.data);
        res?.data.forEach((lang) => {
          if (lang.default) {
            setSelectedLang(lang);
            i18n?.changeLanguage(lang.code).then();
          }
        });
        let defaultSelect = res?.data?.filter((d) => {
          if (d?.default == true) {
            return d;
          }
        });
        ServiceTypeListApi(defaultSelect[0]?.code);
        context.setAppData({
          ...context,
          langCode: defaultSelect[0]?.code,
        });
        setInAsyncStorage(SESSION_KEY?.LANGUAGE, {
          langCode: defaultSelect[0]?.code,
        });
      })
      .catch((err) => {});
  };

  // -------------------------------------
  const ServiceTypeListApi = (lang) => {
    serviceTypeApi(null, lang)
      .then((res) => {
        setIsLoading(false);
        setSerTypeList(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // -------------------------------------

  const handleLang = (e) => {
    ServiceTypeListApi(e?.target?.value);
    setSelectedLang(e?.target?.value);
    setSelectAY("");
    setServiceList([]);
    i18n?.changeLanguage(e?.target?.value);
    setInAsyncStorage(SESSION_KEY?.LANGUAGE, {
      langCode: e?.target?.value,
    });
    context.setAppData({
      ...context,
      langCode: e?.target?.value,
    });
  };

  const _onAreYou = (val) => {
    setSelectAY(val?.uid);
    setServiceList(val?.services);
    let ESCORT_DATA;
    if (serTypeList[0].uid == val?.uid) {
      ESCORT_DATA = [
        { id: 1, name: `${t`ESCORT`}`, value: "ESCORT" },
        { id: 2, name: `${t`ESCORT_PATIENT`}`, value: "ESCORT_PATIENT" },
      ];
    }
    if (serTypeList[1].uid == val?.uid) {
      ESCORT_DATA = [
        { id: 1, name: "ESCORT", value: "ESCORT" },
        { id: 3, name: "ESCORT_STUDENT", value: "ESCORT_STUDENT" },
      ];
    }
    setInAsyncStorage(SESSION_KEY?.ESCORT_LIST, ESCORT_DATA);
    setInAsyncStorage(SESSION_KEY?.TYPE, val);
    context.setAppData({
      ...context,
      appData: {
        ...context?.appData,
        data: {
          ...context?.appData?.data,
          [API_KEY?.customer]: { ...API_KEY?.customer, type: val?.uid },
        },
      },
    });
  };

  const _onService = (val, i) => {
    setSelService(val?.uid);
    let dates = val?.dates?.map((d) => {
      return moment(d?.date);
    });
    setInAsyncStorage(SESSION_KEY?.SERV, val);
    setInAsyncStorage(SESSION_KEY?.APM_DATES, dates);
    setInAsyncStorage(SESSION_KEY?.SERV_UID, val?.uid);
    context.setAppData({
      ...context,
      serviceUid: val?.uid,
    });
  };

  return (
    <div className="main">
      {isLoading ? (
        <div
          className="services"
          style={{
            height: 240,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div className="loader"></div>
        </div>
      ) : (
        <div className="services">
          {language?.length != 0 && (
            <div className="language">
              <div className="language-content">
                <div>
                  <span>{t(`${TEXT?.LANGUAGE}`)}</span>
                </div>
                <hr
                  style={{
                    width: "1px",
                    height: "40px",
                  }}
                />
                <div>
                  <select
                    defaultValue={selectedLang?.code}
                    onChange={handleLang}
                    style={{ borderBottom: 0 }}
                  >
                    {language.map((item, i) => (
                      <option key={i} value={item?.code}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          {/* -------------------------------------- */}

          {serTypeList?.length != 0 && (
            <div className="service-heading">
              <h4>{t(`${TEXT?.ARE_YOU}`)}</h4>
            </div>
          )}

          <div className="services-types">
            {serTypeList?.map((d, i) => {
              let sel = selectAY == d?.uid;
              return (
                <div
                  key={i}
                  className="service"
                  style={{
                    backgroundColor: sel ? THEME_COLOR : WHITE,
                    color: sel ? "white" : "black",
                  }}
                  onClick={() => {
                    _onAreYou(d);
                    setSelService("");
                  }}
                >
                  <div
                    className="background"
                    style={{
                      backgroundColor: sel ? WHITE : YELLOW_LIGHTER,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <img className="card" src={d?.iconPath} alt="visitCard" />
                  </div>
                  <p className="visit_type">{d?.name}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* ---------------------------------------------- */}

      {serviceList?.length != 0 && (
        <div className="services">
          <div className="service-heading">
            <h5>{t(`${TEXT?.TYPE_OF_SER}`)}</h5>
          </div>
          <div className="services-types">
            {serviceList?.map((d, i) => {
              let isSelect = selService == d?.uid;

              return (
                <div
                  key={i}
                  className="service"
                  style={{
                    backgroundColor: isSelect ? THEME_COLOR : WHITE,
                    color: isSelect ? "white" : "black",
                  }}
                  onClick={() => {
                    _onService(d, i);
                    isSelect ? setComplete(0) : setComplete(1);
                  }}
                >
                  <div
                    className="background"
                    style={{
                      backgroundColor: isSelect ? WHITE : YELLOW_LIGHTER,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <img className="card" src={d?.iconPath} alt="visitCard" />
                  </div>
                  <p className="visit_type">{d?.name}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {!selService == "" && !selectAY == "" && <FirstVisit />}
    </div>
  );
}

export default Services;
