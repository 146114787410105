import "../../css/Accordion.css";
import React, { useEffect, useState } from "react";
import Images from "./../../config/Images";
import { THEME_COLOR, THEME_COLOR_LIGHT } from "../../config/colors";
import {t} from "i18next";

const Accordion = ({ title, Content, complete, setComplete, current, ind }) => {
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    // if (complete >= current) {
    setIsActive(true);
    // }
  }, []);

  return (
    <div className="accordion">
      <div className="accordion-item">
        <div
          className="accordion-title"
          style={{
            backgroundColor: isActive ? THEME_COLOR : THEME_COLOR_LIGHT,
            color: isActive ? "white" : "black",
            borderBottom: isActive ? "none" : `1px solid ${THEME_COLOR}`,
            fontFamily: isActive ? "Roboto" : "Inter",
            alignItems: "center",
          }}
          onClick={() => {
            complete >= current && setIsActive(true);
          }}
        >
          <div
            className="main-container"
            style={{
              width: "-webkit-fill-available",
              backgroundColor: "transparent",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {ind === 0 && (
                <img
                  className="downArrow"
                  src={Images.Logo}
                  alt="downArrow"
                  style={{ marginRight: 20, height: 50, width: 50 }}
                />
              )}
              <p
                className={
                  isActive ? `accordion-title-p-sel` : `accordion-title-p`
                }
                style={{ textAlign: isActive ? "center" : "left" }}
              >
                {title}
              </p>
            </div>
              {ind === 0 && (
                  <div style={{marginTop: 35, textAlign: 'center', textTransform: 'uppercase'}}>
                      <h6>{t(`${"Your journey to a new smile starts here"}`)}</h6>
                  </div>
              )}
          </div>
        </div>
        {isActive && (
          <div className="accordion-content">
            {<Content setComplete={setComplete} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default Accordion;
