import axios from "axios";
import API_CONSTANT from "./ApiConstant";

const api = axios.create({
  baseURL: API_CONSTANT.BASE_URL,
});

const getHeader = (lang) => {
  return {
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": lang != undefined && lang != "" ? lang : "",
    },
  };
};

const get = async (endPoint, data, isHeader) => {
  const header = getHeader(isHeader);
  return api.get(endPoint, header);
};

const post = async (endPoint, data, isHeader) => {
  const header = getHeader(isHeader);
  return api.post(endPoint, data, header);
};

export { get, post };
