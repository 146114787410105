import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppProvider";
import "../../css/Payment.css";
import {
  getFromAsync,
  setInAsyncStorage,
  TEXT,
  validationSchema,
} from "../../services/utils";
import {
  custAppointmentApi,
  paymentTypeApi,
} from "../../store/ApiMethod/action";
import { SESSION_KEY } from "../../constant";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { SUCC_COLOR } from "../../config/colors";
import Image from "../../config/Images";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Payment = ({ setComplete }) => {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentTypeList, setPaymentTypeList] = useState([]);

  const [serviceUid, setServiceUid] = useState("");
  const [language, setLanguage] = useState("");
  const [patientInfo, setPatientInfo] = useState("");
  const [accommodation, setAccommodation] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [estimatDateTime, setEstimatDateTime] = useState("");
  const [type, setType] = useState("");
  const [escortData, setEscortData] = useState([]);
  const [accomCheckVal, setAccomCheckVal] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);

  const appData = useContext(AppContext);

  useEffect(() => {
    getData();
    if (appData?.appData?.langCode != undefined) {
      payMethod();
    }
    // setComplete(3);
  }, [appData]);

  useEffect(() => {
    payMethod();
  }, []);

  const payMethod = async () => {
    let lang = await getFromAsync(SESSION_KEY?.LANGUAGE);
    PaymentTypeApi(lang?.langCode);
  };

  const getData = async () => {
    let lang = await getFromAsync(SESSION_KEY?.LANGUAGE);
    let escort = await getFromAsync(SESSION_KEY?.ESCORT_DATA);
    let patientInfo = await getFromAsync(SESSION_KEY?.PATIENT_INFO);
    let accomData = await getFromAsync(SESSION_KEY?.ACCOMODATION);
    let datetime = await getFromAsync(SESSION_KEY?.EST_DATE_TIME);
    let sUid = await getFromAsync(SESSION_KEY?.SERV_UID);
    let type = await getFromAsync(SESSION_KEY?.TYPE);
    let accomCheck = await getFromAsync(SESSION_KEY?.ACCOM_CHECK);
    let startDate = await getFromAsync(SESSION_KEY?.START_DATE);
    let endDate = await getFromAsync(SESSION_KEY?.END_DATE);

    setEscortData(escort);
    setStartDate(startDate);
    setEndDate(endDate);
    setAccomCheckVal(accomCheck);
    setLanguage(lang);
    setPatientInfo(patientInfo);
    setAccommodation(accomData);
    setEstimatDateTime(datetime);
    setServiceUid(sUid);
    setType(type);
  };

  const PaymentTypeApi = (lang) => {
    paymentTypeApi(null, lang).then((res) => {
      setIsLoading(false);
      setPaymentTypeList(res?.data);
    });
  };

  const setPayType = (event) => {
    setPaymentType(event?.target?.value);
    setInAsyncStorage(SESSION_KEY?.PAY_TYPE, event?.target?.value).then();
    appData.setAppData({
      ...appData,
      payType: event?.target?.value,
    });
  };

  const _onCheckData = () => {
    validationSchema
      .validate(patientInfo)
      .then((valid) => _onSubmit())
      .catch((e) => toast.error(t(`${e?.message}`)));
  };

  const _onSubmit = () => {
    getData();
    if (!accomCheckVal) {
      toast.error(t(`${"Please select accommodation"}`));
    } else if (!startDate && !endDate) {
      toast.error(t(`${"Please select appointment date"}`));
    } else if (!paymentType) {
      toast.error(t(`${"Please select payment option"}`));
    } else {
      setBtnDisable(true);
      let escort = escortData?.map((d) => {
        return { ...d, country: { uid: d?.country } };
      });

      let pfData = {
        ...patientInfo,
        type: type?.name,
        country: { uid: patientInfo?.country },
        escort: escort == undefined ? [] : escort,
      };

      let accom = {
        uid: accommodation ? accommodation?.uid : "",
      };

      let payment = {
        uid: paymentType,
      };

      let service = {
        uid: serviceUid,
      };

      let data = {
        customer: pfData,
        service: service,
        paymentType: payment,
        startDate: moment(startDate).format("DD.MM.YYYY"),
        endDate: moment(endDate).format("DD.MM.YYYY"),
        arrivalDateTime: `${moment(estimatDateTime?.date).format(
          "DD.MM.YYYY"
        )} ${moment(estimatDateTime?.time).format("HH:mm:ss")}`,
        transferRequired: estimatDateTime ? true : false,
      };

      if (accommodation) {
        data.accommodation = accom;
      }

      custAppointmentApi(data, language?.langCode)
        .then((res) => {
          setBtnDisable(false);
          toast.success(
            t(`${"You have successfully send request for appointment"}`)
          );
          navigation("success");
          sessionStorage.clear();
        })
        .catch((err) => {
          openModal();
          setBtnDisable(false);
          setErrorMsg(err?.message);
        });
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="payment">
      <div className="payment-content">
        <div className="" style={{ paddingBottom: 10 }}>
          <h4>{t(`${TEXT?.CHOOSE_PAYMENT}`)}</h4>
        </div>
        {isLoading ? (
          <div
            className=""
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div className="loader"></div>
          </div>
        ) : (
          <div onChange={(e) => setPayType(e)}>
            {paymentTypeList?.map((rd, i) => {
              return (
                <div key={i}>
                  <div className="payment-type">
                    <input
                      id={rd?.uid}
                      type="radio"
                      name="payment"
                      value={rd?.uid}
                      disabled={rd.disabled}
                    />
                    <label htmlFor={rd?.uid} style={{ margin: 0 }}>
                      <p>{rd?.name}</p>
                    </label>
                  </div>
                  {rd?.uid === paymentType && rd?.note !== undefined && (
                    <div style={{ display: "flex", padding: "25px", backgroundColor: '#f0f0f0' }}>
                      <h2 className="accordion-title-p">
                        <span
                          style={{ color: "red" }}
                          dangerouslySetInnerHTML={{ __html: rd?.note }}
                        />
                      </h2>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="main-div">
        <div
          className="df"
          style={{
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 50,
            display: "flex",
          }}
        >
          <button
            type="submit"
            className="button"
            style={{
              width: 200,
              height: 50,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
            onClick={() => (!btnDisable ? _onCheckData() : "")}
          >
            {t(`${TEXT?.SUBMIT}`)}
            {btnDisable && (
              <div className="btn_loader" style={{ marginLeft: 5 }} />
            )}
          </button>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div>
          <div className="df" style={{ justifyContent: "end" }}>
            <div onClick={closeModal}>
              <img
                src={Image?.CloseBlack}
                style={{ width: 25, height: 25 }}
                alt=""
              />
            </div>
          </div>
          <div
            className="df"
            style={{ justifyContent: "center", padding: "20px 0px" }}
          >
            <img
              src={Image?.Remove}
              style={{ width: "30%", height: "30%" }}
              alt=""
            />
          </div>
          <div
            className=""
            style={{ justifyContent: "center", padding: "20px 0px" }}
          >
            <p style={{ fontSize: 26, color: SUCC_COLOR, textAlign: "center" }}>
              {errorMsg}
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Payment;
