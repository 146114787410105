import closeIcon from "./assets/images/close.png";
import Arrow from "./assets/images/Arrow.png";
import downArrow from "./assets/images/arrow_down.png";
import upArrow from "./assets/images/UpArrow.png";
import visitCard from "./assets/images/visitCard.png";
import whiteRound from "./assets/images/whiteRound.png";
import yellowRound from "./assets/images/yellowRound.png";
import yellowCard from "./assets/images/yellowCard.png";
import Orthodontic from "./assets/images/Orthodontic.png";
import Emergency from "./assets/images/Emergency.png";
import calendar from "./assets/images/calendar.png";
import clock from "./assets/images/clock.png";
import add from "./assets/images/add.png";
import remove from "./assets/images/remove.png";
import success from "./assets/images/success.png";
import closeBlack from "./assets/images/closeBlack.png";
import logo from "./assets/images/D.jpg";

const Image = {
  closeIcon: closeIcon,
  Arrow: Arrow,
  downArrow: downArrow,
  whiteRound: whiteRound,
  yellowRound: yellowRound,
  visitCard: visitCard,
  Orthodontic: Orthodontic,
  Emergency: Emergency,
  yellowCard: yellowCard,
  calendar: calendar,
  clock: clock,
  upArrow: upArrow,
  Add: add,
  Remove: remove,
  Success: success,
  CloseBlack: closeBlack,
  Logo: logo,
};

export default Image;
